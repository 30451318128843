import Vue from 'vue';
import App from './App.vue'
import router from '@/router';
import store from '@/store';
import * as api from '../src/network/api'
import * as Config from '../src/libs/config.js'
import * as db from '../src/libs/db.js'
import locale from 'element-ui/lib/locale/lang/zh-CN';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipBoard from 'vue-clipboard2';
import scroll from 'vue-seamless-scroll';
import "../public/font/patrickFont.css";

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
 
Vue.use(scroll)

//filter 相关处理
//显示价格
Vue.filter('usePrice',(item)  => {
	// console.log('usePrice', item.price, item.price_plus);
	if(store.state.userInfo && store.state.userInfo.is_auth == 2){
		return parseFloat(item.price_plus).toFixed(2) || '0.00';
	}
	return parseFloat(item.price).toFixed(2) || '0.00';
});
//计算总价
Vue.filter('useTotal',(item)  => {
	console.log('useTotal', item.price, item.price_plus, item.num);
	if(store.state.userInfo && store.state.userInfo.is_auth == 2){
		return parseFloat(item.price_plus*item.num).toFixed(2) || '0.00';
	}
	return parseFloat(item.price*item.num).toFixed(2) || '0.00';
});

locale.el.pagination = {
	goto: 'Jump to',
	pagesize: '',
	total: `Jump to  {total}  strip`,
	pageClassifier: 'page',
};

Vue.use(ElementUI);
Vue.use(VueClipBoard)

Vue.prototype.$api = api
Vue.prototype.$config = Config;
Vue.prototype.$db = db;

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');