import md5 from 'js-md5';
import sha1 from 'js-sha1'

const getSign = (params) => {
    var paramStr = ""
    if (typeof params == "string") {
        paramStr = params
    } else if (typeof params == "object") {
        var arr = []
        for (var i in params) {
            if (Object.prototype.hasOwnProperty.call(params, i) && i !== 'sign') {
                if (typeof params[i] == 'boolean'  || params[i] === '0' || !params[i]){
                    continue;
                }
                arr.push((i + "=" + params[i]))
            }
        }
        paramStr = arr.join(("&@#"))
    }

    if (paramStr) {
        // console.log(paramStr)
        var newParamStr = paramStr.split("&@#").sort().join("&")
        // console.log('加密串===', newParamStr)
        // console.log('加密串==@', encodeURIComponent(newParamStr))
        var sha1res = sha1(newParamStr);
        // console.log('sha1的值：',sha1res)
        var sign = md5(sha1res);
        // console.log('md5的值：',sign)
        if (typeof params == "string") {
            params += ("&sign=" + sign)
        } else {
            params["sign"] = sign
        }
    }
    return params
}

export default getSign

