<template>
	<div class="advertiseBox" v-if="isAdvertise && advOpenData.picurl">
		<div class="box" style="position: relative;">
			<img :src="advOpenData.picurl" alt="" class="adverImg">
			<img src="../../public/images/public/maskClose.png" alt="" class="closeImg" @click="closeAdvertise">
			<div class="login">
				<div class="tips">By submitting this form, you agree to receive email marketing messages from Asian
					Mall. You can unsubscribe at any time by following the unsubscribe instructions in any email we sent
					to you. View Terms & Privacy.</div>
				<el-input class="email" v-model="email" placeholder="Email Address"></el-input>
				<el-button class="btn" @click="recordEmail">CONTINUE</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isAdvertise: true,
				email: '',
				advOpenData: {
					picurl: ''
				},
				time:0,
				advTime:0
			}
		},
		async mounted() {
			await this.$api.getAdv(7).then(res => {
				this.advOpenData = res.data.items[0];
			});
			
		},
		created() {
			this.getNowTime()
			if(this.time - this.$db.get("advertiseTime") >7200){
				this.isAdvertise = true
			}else{
				this.isAdvertise = false
			}
		},
		methods: {
			// 获取当前的时间
			getNowTime() {
				let dt = new Date().getTime()/1000
				this.time = dt
				console.log('此刻时间', this.time)
			},
			closeAdvertise() {
				this.isAdvertise = false
				let isTime = new Date().getTime()/1000
				this.advTime = isTime
				this.$db.set("advertiseTime",isTime)
			},
			recordEmail() {
				console.log('recordEmail', this.email)
				if (!this.email) {
					alert('Please enter Email')
					return;
				}
				this.$api.recordEmail(this.email).then(res => {
					this.email = '';
					this.closeAdvertise();
					alert(res.msg);
				});
			},
		},
	}
</script>

<style scoped>
	.login {
		position: absolute;
		bottom: 100px;
		width: 580px;
		height: 240px;
	}

	.login .tips {
		background-color: #FFF;
		padding: 10px 20px;
	}

	.login .email {
		background-color: #FFF;
		margin-top: 15px;
		margin-left: 0;
		width: 580px;
	}

	.login .btn {
		width: 100%;
		text-align: center;
		font-weight: bold;
		background: #000;
		color: #FFF;
		border: 0;
		height: 60px;
		font-size: 32px;
	}

	.advertiseBox {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99999999999;
	}

	.advertiseBox .box {
		width: 1200px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	.advertiseBox .box .adverImg {
		width: 740px;
		height: 500px;
	}

	.advertiseBox .box .closeImg {
		width: 50px;
	}
</style>
