import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter);


const routes = [{
		path: "/",
		name: "home",
		meta: {
			title: "Home",
			content: {
				keywords: "先农氏,智能碾米机,先农氏智能无人碾米机",
				description: "先农氏智能无人碾米机， 中国首台智能无人碾米机， 专利产品。 拥有先进核心技术， 更智能、 碾米速度更快， 噪音更小， 操作更简便， 现碾现售。 新鲜， 安全， 营养。"
			}
		},
		component: () => import("@/pages/home.vue")
	},
	{
		path: "/cart",
		name: "cart",
		meta: {
			title: "Shopping Cart"
		},
		component: () => import("@/pages/cart.vue")
	}, {
		path: "/checkout",
		name: "checkout",
		meta: {
			title: "Checkout"
		},
		component: () => import("@/pages/checkout.vue")
	},
	// {
	// 	path: '/list',
	// 	name: "list",
	// 	meta: {
	// 		title: "Product List"
	// 	},
	// 	component: () => import("@/pages/list.vue")
	// },
	{
		path: "/listRecommen",
		name: "listRecommen",
		meta: {
			title: "Pre-sale"
		},
		component: () => import("@/pages/listRecommen.vue")
	}, {
		path: "/notify",
		name: "notify",
		meta: {
			title: "Payment Notify"
		},
		component: () => import("@/pages/notify.vue")
	}, {
		path: "/orderlist",
		name: "orderlist",
		meta: {
			title: "My Order"
		},
		component: () => import("@/pages/orderlist.vue")
	}, {
		path: "/coupon",
		name: "coupon",
		meta: {
			title: "My Coupon"
		},
		component: () => import("@/pages/coupon.vue")
	}, {
		path: "/qrcode",
		name: "qrcode",
		meta: {
			title: "Referral code"
		},
		component: () => import("@/pages/qrcode.vue")
	},
	{
		path: "/faq",
		name: "faq",
		meta: {
			title: "FAQ's"
		},
		component: () => import("@/pages/listFaq.vue")
	}, {
		path: "/preCheckout",
		name: "preCheckout",
		meta: {
			title: "Pre-sale settlement"
		},
		component: () => import("@/pages/preCheckout.vue")
	},
	{
		path: '/page',
		name: "page",
		meta: {
			title: "Page"
		},
		component: () => import("@/pages/page.vue")
	}, {
		path: "/spe/:one?",
		name: "listBuy",
		meta: {
			title: "Groupby"
		},
		component: () => import("@/pages/listBuy.vue")
	},
	{
		path: "/pre/:one?",
		name: "listPre",
		meta: {
			title: "Pre-sale"
		},
		component: () => import("@/pages/listPre.vue")
	}, {
		path: "/list/:id?/:one?/:two?/:keyword?",
		name: "list",
		meta: {
			title: "Product List",
			content: {
				keywords: '钇活儿用工,灵活用工,税收优惠,共享经济,劳务派遣,钇活儿',
				description: '钇活儿为企业提供灵活用工、税收优惠、共享经济、劳务派遣等解决方案。钇活儿SaaS级、PaaS级灵活用工管理平台通过整合“人才”和“企业”双方的供求关系，让人才可以足不出户就业，也为企业解决了灵活用工面临的法律风险。'
			}
		},
		component: () => import("@/pages/list.vue")
	}, {
		path: "/groupbyDetails/:one?/:id?/:name?",
		name: "groupbyDetails",
		meta: {
			title: "Groupby Detail"
		},
		component: () => import("@/pages/groupbyDetails.vue")
	},
	{
		path: "/preDetails/:one?/:id?/:name?",
		name: "preDetails",
		meta: {
			title: "Pre-sale Detail"
		},
		component: () => import("@/pages/preDetails.vue")
	},
	{
		path: `/goods/:one?/:id/:name?`,
		name: "commodityDetails",
		meta: {
			title: "Product Detail"
		},
		component: () => import("@/pages/commodityDetails.vue")
	}
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {
				x: 0,
				y: 0
			};
		}
	}
});

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	// if (to.meta.title) {
	// 	document.title = process.env.VUE_APP_BASE_NAME + '-' + to.meta.title
	// }
		if(to.meta.title!="home" || to.meta.title!="Product Detail" || to.meta.title!="Pre-sale Detail" || to.meta.title!="Groupby Detail" || to.meta.title!="Product List"){
			if (to.meta.title) {
				document.title = process.env.VUE_APP_BASE_NAME + '-' + to.meta.title
			}
		}
	next()
})

export default router;
