<template>
	<div class="header">
		<div class="headerTop">
			<div class="headerContent">
				<div class="headerContent_left cursor">
					<!-- <img src="../../public/images/public/language.png" alt="" class="header_language">
					<el-select v-model="value" placeholder="English">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select> -->
				</div>
				<div class="flex align-center">
					<img src="../../public/images/public/announcement.png" alt="" class="header_announcement">
					<vue-seamless-scroll :data="noticeData" :class-option="optionLeft" class="seamless-warp2">
						<ul class="item" style="width: 800px;">
							<li v-for="item in noticeData" class="flex-nowrap" style="cursor: pointer;"
								@click="showNotice(item);">{{item.title}}</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</div>
		</div>
		<div class="headerMiddle">
			<div class="headerContent">
				<img src="../../public/images/public/logo.png" alt="" class="header_logoimg cursor"
					@click="toPage('/')">
				<el-input placeholder="Search products" v-model="keyword" type="text" @keydown="search" @keyup="search">
					<el-button slot="append" @click="search" icon="el-icon-search" class="cursor"
						style="margin-right: -4px;"> Search </el-button>
				</el-input>
				<div class="header_shopcartBox cursor">
					<img src="../../public/images/public/shopCart.png" alt="" class="header_shopcartImg"
						@click="toPage('/cart')">
					<div></div>
				</div>
				<div class="myAccount">
					<el-popover placement="bottom" width="260" trigger="manual" v-model="myAccount"
						class="headerPopover">
						<div class="widBox borbot">
							<p class="popoverTitle heightDiv flex align-center">My Account</p>
						</div>
						<div class="widBox">
							<div class="widBox line flex align-center">
								<a href="javascript:void(0);" class="cursor flex align-center"
									@click="toPage('/orderlist');">
									<img src="../../public/images/public/shopList.png" alt="" class="iconImg">
									<p class="popoverTitle">Lists</p>
								</a>
							</div>
							<div class="widBox line flex align-center">
								<a href="javascript:void(0);" class="cursor flex align-center"
									@click="toPage('/coupon')">
									<img src="../../public/images/public/coupon.png" alt="" class="iconImg">
									<p class="popoverTitle">Coupon</p>
								</a>
							</div>
							<div class="widBox line flex align-center">
								<a href="javascript:void(0);" class="cursor flex align-center" @click="qrcodeMask=true">
									<img src="../../public/images/public/qrcode.png" alt="" class="iconImg">
									<p class="popoverTitle">Referral code</p>
								</a>
							</div>
							<div class="widBox line flex align-center borbot">
								<a href="javascript:void(0);" class="cursor flex align-center" @click="logout">
									<img src="../../public/images/public/logout.png" alt="" class="iconImg">
									<p class="popoverTitle">Log Out</p>
								</a>
							</div>
						</div>
						<!-- <div class="flex align-center widBox justify-end logoutBox">
							<button class="flex align-center">
								<p class="logout cursor" @click="logout">Log Out</p>
								<img src="../../public/images/public/logout.png" alt="" class="logoutImg cursor">
							</button>
						</div> -->
						<el-button slot="reference">
							<img :src="userInfo.avatar" v-if="userToken" @click="myAccount=!myAccount"
								class="header_myImg cursor" />
							<img src="../../public/images/public/my.png" alt="" class="header_myImg cursor"
								@click="personage" v-else>

						</el-button>
					</el-popover>
				</div>
			</div>
		</div>
		<div class="headerNav flex align-center">
			<div class="headerContent">
				<el-dropdown :hide-on-click="false" v-for="(item,index) in navList" v-if="index<8"
					style="overflow-x: hidden;">
					<div class="flex align-center justify-center flex-nowrap cursor"
						:class="index==navlistIndex?'headerNav_boxA':'headerNav_box'" @click="navSelect(index,item)">
						<!-- <a class="headera flex justify-center align-center"
							:href="item.id?'/list?id='+item.id:'/'">{{item.name_en}}</a> -->
						<a class="headera flex justify-center align-center" @click="pageTo(item)">{{item.name_en}}</a>
					</div>
					<el-dropdown-menu slot="dropdown" v-if="item.id != 0">
						<!-- <el-dropdown-item v-for="(cate, i) in item.child"><a
								:href="'/list?id='+cate.id">{{cate.name_en}}</a></el-dropdown-item> -->
						<el-dropdown-item v-for="(cate, i) in item.child">
							<a @click="pageTo(cate)">{{cate.name_en}}</a>
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- 公告 -->
		<div class="loginmaskBox">
			<el-dialog :visible.sync="qrcodeMask" width="700px">
				<div class="dialogBox flex flex-column justify-start align-start" style="padding: 30px 55px 54px;">
					<p class="maskTitle">Referral code</p>
					<div class="maskContent" style="display: flex; justify-content: center; width: 100%;">
						<img :src="qrcode.images" height="200" width="200" />
					</div>
					<div class="maskContent" style="width: 100%; text-align: center;">邀请链接：{{qrcode.share_url}}</div>
				</div>
			</el-dialog>
		</div>
		<!-- 公告 -->
		<div class="loginmaskBox">
			<el-dialog :visible.sync="noticeMask" width="700px">
				<div class="dialogBox flex flex-column justify-start align-start" style="padding: 30px 55px 54px;">
					<p class="maskTitle">{{noticeDetail.title}}</p>
					<div class="maskContent" v-html="noticeDetail.content"></div>
				</div>
			</el-dialog>
		</div>
		<!-- 登录 -->
		<div class="loginmaskBox">
			<el-dialog :visible.sync="loginMask" width="700px">
				<!-- :before-close="handleClose" -->
				<!-- 登录 -->
				<div class="dialogBox flex flex-column justify-start align-start" v-if="isLoginShow==1">
					<p class="maskTitle">SIGN IN</p>
					<p class="dontTxt cursor" style="text-align: right;" @click="isLoginShow = 2">Don't have an account?
						Sign Up</p>
					<p class="inputTitle">
						<!-- Email -->Telphone
					</p>
					<input type="text" placeholder="Please input your phone number" placeholder-class="placInput"
						v-model="loginData.email" class="inputbox">
					<p class="inputTitle">Password</p>
					<input type="password" placeholder="Please input password" class="inputbox"
						v-model="loginData.password">
					<p class="forgotTxt cursor" @click="isLoginShow = 3">Forgot your password?</p>
					<button class="cursor flex justify-center align-center" @click="login">SIGN IN</button>
				</div>
				<!-- 忘记密码 -->
				<div class="dialogBox flex flex-column justify-start align-start" v-if="isLoginShow==3">
					<p class="maskTitle">RESET PASSWORD</p>
					<p class="dontTxt cursor" style="text-align: right;" @click="isLoginShow = 2">Don't have an account?
						Sign Up</p>
					<p class="inputTitle">Telphone <i>*</i></p>
					<div style="display: flex;">
						<input v-model="forgetData.account" type="text" placeholder="Please input telphone 04XX XXXXXX"
							placeholder-class="placInput" class="inputbox" style="width: 400px;">
						<button @click="sendCodeForget"
							style="margin-top: 16px; width:auto; margin-left:5px; padding: 0 20px; cursor: pointer;">{{verificationForget?'Send code':timerForget+'s'}}</button>
					</div>
					<p class="inputTitle">Verification code <i>*</i></p>
					<input type="text" v-model="forgetData.code" placeholder="Please input verification code"
						placeholder-class="placInput" class="inputbox">
					<p class="inputTitle">Password <i>*</i></p>
					<input type="password" v-model="forgetData.password" placeholder="please input password"
						class="inputbox">
					<button class="cursor flex justify-center align-center" @click="forget">SUBMIT</button>
				</div>
				<!-- 注册 -->
				<div class="dialogBox flex flex-column justify-start align-start" v-if="isLoginShow==2">
					<p class="maskTitle">SIGN UP</p>
					<p class="createTxt">Create an account with us to:</p>
					<p class="dontTxt cursor" style="text-align: right;" @click="isLoginShow = 1">Already have an
						account? Sign In</p>
					<ul class="ulBox flex flex-column">
						<li class="flex align-start">
							<p></p> Get the best seafood, veg, meat, dairy, gourmet groceries and fruit delivered to
							your door.
						</li>
						<li class="flex align-start">
							<p></p> Receive exclusive emails with the latest news with what is fresh and in season, plus
							exclusive offers.
						</li>
						<li class="flex align-start">
							<p></p> Track your orders and manage your account, billing and address.
						</li>
					</ul>
					<p class="fillTxt">Please fill in the form details below. Fields marked with <i>* are mandatory.</i>
					</p>
					<p class="line"></p>
					<p class="inputTitle">Telphone <i>*</i></p>
					<div style="display: flex;">
						<input v-model="regData.account" type="text" placeholder="Please input telphone 04XX XXXXXX"
							placeholder-class="placInput" class="inputbox" style="width: 400px;">
						<button @click="sendCode"
							style="margin-top: 16px; width:auto; margin-left:5px; padding: 0 20px; cursor: pointer;">{{verification?'Send code':timer+'s'}}</button>
					</div>
					<p class="inputTitle">Verification code <i>*</i></p>
					<input type="text" v-model="regData.code" placeholder="Please input verification code"
						placeholder-class="placInput" class="inputbox">
					<p class="inputTitle">Email<i>*</i></p>
					<input type="text" v-model="regData.email" placeholder="Please input email" class="inputbox">
					<p class="inputTitle">Password<i>*</i></p>
					<input type="password" v-model="regData.password" placeholder="Please input password"
						class="inputbox">
					<p class="inputTitle">Invitation code</p>
					<input type="text" v-model="regData.fid" placeholder="Please input invitation code"
						class="inputbox">
					<el-checkbox v-model="privacy" class="privacy">Agree to the user policy and privacy agreement
					</el-checkbox>
					<button class="cursor flex justify-center align-center signBtn" @click="doSubmit">SIGN UP</button>
				</div>
				<div class="closeBox">
					<img src="../../public/images/public/maskClose.png" alt="" class="maskClose cursor"
						@click="loginMask = false">
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import scroll from 'vue-seamless-scroll'
	export default {
		data() {
			return {
				keyword: '',
				fid: 0,
				qrcodeMask: false,
				qrcode: {
					images: '',
					share_url: ''
				},
				loginData: {
					email: '',
					password: ''
				},
				options: [{ //语言切换
						value: 'en-US',
						label: 'English'
					}
					/*, {
										value: '选项2',
										label: '中文'
									}*/
				],
				value: '',

				noticeMask: false,
				noticeData: [],
				noticeDetail: {},
				navList: [
					// {
					// 	txt:'Home',
					// 	href:'/',
					// 	navList:[]
					// }
				],
				isMenu: 0, //下拉显示隐藏
				navlistIndex: -1,

				loginMask: false, //登录注册弹窗
				isLoginShow: 0, //1登录，2注册，3忘记密码

				privacy: false, //注册用户勾选隐私
				myAccount: false, //登录之后的下拉弹窗

				//注册验证码
				verification: true,
				regData: {
					account: '',
					code: '',
					email: '',
					password: '',
					fid: ''
				},
				timer: 60,

				//找回密码
				verificationForget: true,
				forgetData: {
					account: '',
					code: '',
					password: '',
				},
				timerForget: 60,
			}
		},
		components: {
			scroll
		},
		async created() {
			let headerCategory = await this.$api.getFirstCategory();
			if (this.$route.query?.fid) {
				this.fid = parseInt(this.$route.query.fid || 0);
				this.regData.fid = this.fid;
			}
			this.navList = [
				// {
				// 	id: 0,
				// 	name_en: 'Home',
				// 	navList: []
				// },
				...headerCategory.data
			];
			this.navList.forEach(item => {
				if (item.child) {
					if (item.child.length != 0) {
						this.isMenu = 1;
					}
				}
			})

			//noticeData 公告列表
			let notice = await this.$api.getArticleList(1, 3, 4);
			this.noticeData = notice.data.data;

			//二维码
			let domain = location.origin + '/';
			await this.$api.userQrcode(domain).then(res => {
				this.qrcode = res.data;
			});
		},
		methods: {
			pageTo(item) {
				this.$router.push({
					name: "list",
					params: {
						one: item.name_en,
						id: item.id,
					}
				})
			},
			showNotice(data) {
				this.noticeMask = true;
				this.noticeDetail = data;
			},
			search() {
				if (this.keyword.length < 1) {
					return this.$message.error('Please enter a keyword');
				} else {
					// this.$router.push({
					// 	path: '/list?keyword=' + this.keyword
					// })
					this.$router.push({
						name: "list",
						params: {
							keyword: this.keyword,
						}
					})
				}
			},
			// 导航
			navSelect(index, item) {
				this.navlistIndex = index;
			},
			// 个人中心
			personage() {
				//未登录，打开登录弹窗
				this.isLoginShow = 1;
				this.loginMask = true;

				// 登录之后展开弹窗
				// this.myAccount = !this.myAccount
			},
			// 登录
			login() {
				if (!this.loginData.email) {
					return this.$message.error('Please enter email.');
				}
				if (this.loginData.password.length < 6) {
					return this.$message.error('Please enter password.');
				}

				this.$api.userLogin(this.loginData.email, this.loginData.password, '', 'password').then(res => {
					this.$message({
						message: res.msg,
						type: 'success'
					});
					this.$store.commit('setUserToken', res.data.token);
					this.$store.commit('setUserInfo', res.data);
					this.isLoginShow = 0;
					this.loginMask = false;
				});
				//this.loginMask = false;
			},
			//退出登录
			logout() {
				this.$message({
					message: 'Sign out and sign in successfully.',
					type: 'success'
				});
				this.myAccount = false;
				this.$store.commit('clearUserinfo');
			},
			// 忘记密码
			forget() {
				//验证通过
				this.$api.userForget(this.forgetData.account, this.forgetData.code, this.forgetData.password).then(res => {
					this.$message({
						message: res.msg,
						type: 'success'
					});
					this.isLoginShow = 0;
					this.loginMask = false;
				})
			},
			toPage(url) {
				this.myAccount = false;
				// this.$router.push({
				// 	path: url
				// });
				let name = ''
				if (url == '/') {
					name = 'home'
					this.$router.push({
						name: name,
					})
				} else {
					var reg = new RegExp("/"); // 不加'g'，仅删除字符串里第一个"a"
					var a2 = url.replace(reg, "");
					name = a2
					this.$router.push({
						name: name,
					})
				}

			},
			//注册处理开始
			sendCodeForget() {
				if (!this.verificationForget) {
					return this.$message.error(`您点击过快,请稍后再试`)
				}
				if (this.forgetData.account.length == 11) {
					//中国号码
					if (!/^1\d{10}$/.test(this.forgetData.account)) {
						return this.$message.error('手机号不正确')
					}
				} else if (this.forgetData.account.length == 10 || this.forgetData.account.length == 9) {
					if (!/^0?4\d{8}$/.test(this.forgetData.account)) {
						return this.$message.error('手机号不正确')
					}
				} else {
					return this.$message.error('手机号不正确');
				}
				this.$api.sendCode(this.forgetData.account, 'forget').then(res => {
					this.timerForget = 60
					this.verificationForget = false
					this.countDownForget() // 执行验证码计时
				}).catch(err => {
					this.$message.error(err.msg)
				})
			},
			sendCode() {
				if (!this.verification) {
					return this.$message.error(`您点击过快,请稍后再试`)
				}
				if (this.regData.account.length == 11) {
					//中国号码
					if (!/^1\d{10}$/.test(this.regData.account)) {
						return this.$message.error('手机号不正确')
					}
				} else if (this.regData.account.length == 10 || this.regData.account.length == 9) {
					if (!/^0?4\d{8}$/.test(this.regData.account)) {
						return this.$message.error('手机号不正确')
					}
				} else {
					return this.$message.error('手机号不正确');
				}
				this.$api.sendCode(this.regData.account, 'reg').then(res => {
					this.timer = 60
					this.verification = false
					this.countDown() // 执行验证码计时
				}).catch(err => {
					this.$message.error(err.msg)
				})
			},
			// 验证码倒计时
			countDown() {
				let auth_timer = setInterval(() => {
					this.timer-- // 递减时间
					if (this.timer <= 0) {
						this.verification = true
						clearInterval(auth_timer)
					}
				}, 1000)
			},
			countDownForget() {
				let auth_timer = setInterval(() => {
					this.timerForget-- // 递减时间
					if (this.timerForget <= 0) {
						this.verificationForget = true
						clearInterval(auth_timer)
					}
				}, 1000)
			},
			//登录提交
			doSubmit() {
				if (!this.privacy) {
					return this.$message.error('Please agree to the privacy agreement');
				}
				//验证通过
				this.$api.userReg(this.regData).then(res => {
					this.$message({
						message: res.msg,
						type: 'success'
					});
					this.$store.commit('setUserToken', res.data.token);
					this.$store.commit('setUserInfo', res.data);
					this.isLoginShow = 0;
					this.loginMask = false;
				})
			}
		},
		computed: {
			...mapState({
				userToken: state => state.userToken,
				userInfo: state => state.userInfo
			}),
			optionLeft() {
				return {
					direction: 2,
					limitMoveNum: 2
				}
			},
			// 登录弹窗关闭
			handleClose(done) {
				this.loginMask = false;
			}
		}
	}
</script>
<style>
	.maskContent {
		margin-top: 20px;
	}

	/* 顶部导航下拉 */
	.el-dropdown-menu__item {
		font-size: 14px !important;
		font-family: "Arial" !important;
		font-weight: 400 !important;
		color: #444444 !important;
	}

	/* 顶部导航下拉结束 */

	/* 登录 成功的下拉 */
	.header .headerMiddle .myAccount {
		/* width: 34px;
		height: 34px; */
		margin-left: 38px;
	}

	.header .headerMiddle .myAccount .el-button {
		padding: 0 !important;
		width: 34px !important;
		height: 34px !important;
		background: none !important;
		border: none !important;
	}

	.el-popover {
		border-radius: 10px !important;
		padding: 0 !important;
		height: 240px !important;
	}

	.el-popover .borbot {
		border-bottom: 1px solid #e6e6e6;
	}

	.el-popover .heightDiv {
		height: 54px;
	}

	.el-popover .popoverTitle {
		font-size: 18px;
		font-family: "Arial";
		font-weight: bold;
		color: #444444;
		margin-left: 20px;
	}

	.el-popover .line {
		padding-top: 40px;
		height: 40px;
		padding-left: 20px;
	}

	.el-popover .line.borbot {
		padding-bottom: 40px;
	}

	.el-popover .line .popoverTitle {
		margin-left: 11px;
	}

	.el-popover .line .iconImg {
		width: 28px;
		height: 28px;
	}

	.el-popover .logoutBox {
		height: 57px;
		padding-right: 20px;
	}

	.el-popover .logoutBox button {
		background: none;
		border: none;
	}

	.el-popover .logout {
		font-size: 18px;
		font-family: "Arial";
		font-weight: bold;
		color: #8E8E8E;
	}

	.el-popover .logoutBox .logoutImg {
		width: 18px;
		height: 18px;
		margin-left: 6px;
	}


	/* 登录成功的下拉结束 */


	/* 语言切换下拉 */
	.header .headerContent_left .el-select {
		width: 110px !important;
		border: none !important;
		background: none !important;
	}

	.el-input__inner {
		border: none !important;
		background: none !important;
		font-size: 16px !important;
		font-family: "Arial" !important;
		font-weight: 400 !important;
		color: #444444 !important;
		background: #fff !important;
	}

	.el-select .el-input .el-select__caret {
		color: #444 !important;
		font-weight: 700;
	}

	.el-input {
		background: none !important;
	}

	.el-select>.el-input {
		margin-left: 0 !important;
		display: flex !important;
		align-items: center !important;
		justify-content: start !important;
	}

	/* 公告 */
	.seamless-warp2 {
		overflow: hidden;
		height: 25px;
		width: 526px;
		line-height: 25px;
	}

	.seamless-warp2 ul.item {
		display: flex;
		width: 100% !important;
	}

	.seamless-warp2 ul.item li {
		float: left;
		margin-right: 10px;
		font-size: 16px;
		font-family: "Arial";
		font-weight: 400;
		color: #444444;
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		height: 18px;
	}

	.seamless-warp2>div {
		width: 99999px !important;
	}

	/* 搜索 */
	.el-input {
		margin-left: 33px;
		width: 1405px;
		background: #fff;
		height: 55px;
	}

	.el-input-group__append,
	.el-input-group__prepend {
		width: 144px !important;
		background: #4BA022 !important;
		height: 55px !important;
		color: #fff !important;
		font-size: 18px !important;
		font-family: "Arial" !important;
		font-weight: bold !important;
		cursor: pointer !important;
	}

	.el-input-group>.el-input__inner {
		height: 55px !important;
		font-size: 18px !important;
		font-family: "Arial" !important;
		font-weight: 400 !important;
		color: #444444 !important;
	}

	/* 登录弹窗 */
	.loginmaskBox {
		width: 100%;
	}

	.loginmaskBox .el-dialog {
		background: none;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 27vh !important;
		box-shadow: none !important;
	}

	.loginmaskBox .el-dialog .el-dialog__header {
		height: 0 !important;
		padding: 0 !important;
		border-radius: 25px;
	}

	.loginmaskBox .el-dialog .el-dialog__body {
		width: 700px;
		padding: 0;
		border: none;
		background: none;
	}

	.loginmaskBox .dialogBox {
		width: 100%;
		background: #fff;
		border-radius: 25px;
		padding: 72px 55px 54px;
	}

	.loginmaskBox .dialogBox .maskTitle {
		width: 100%;
		text-align: center;
		font-size: 38px;
		font-family: "patrick-hand";
		font-weight: 400;
		color: #444444;
	}

	.loginmaskBox .dialogBox .createTxt {
		width: 100%;
		text-align: center;
		font-size: 18px;
		font-family: 'Arial';
		font-weight: 400;
		color: #444444;
		margin-top: 10px;
	}

	.loginmaskBox .dialogBox .ulBox {
		margin-top: 50px;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: start;
	}

	.loginmaskBox .dialogBox .ulBox li {
		font-size: 16px;
		font-family: Arial;
		font-weight: 400;
		color: #000000;
		line-height: 24px;
		margin-bottom: 20px;
	}

	.loginmaskBox .dialogBox .ulBox li p {
		width: 7px;
		height: 7px;
		background: #444444;
		border-radius: 50%;
		margin: 8px 9px 0 0;
	}

	.loginmaskBox .dialogBox .fillTxt {
		width: 100%;
		text-align: center;
		margin-top: 32px;
		font-size: 16px;
		font-family: "Arial";
		font-weight: 400;
		color: #8E8E8E;
	}

	.loginmaskBox .dialogBox .fillTxt i {
		color: #FF9000;
		font-style: normal;
	}

	.loginmaskBox .dialogBox .line {
		margin-top: 45px;
		width: 100%;
		height: 1px;
		background: #EEEEEE;
	}

	.loginmaskBox .dialogBox .inputTitle {
		margin-top: 50px;
		font-size: 20px;
		font-family: "Arial";
		font-weight: bold;
		color: #444444;
	}

	.loginmaskBox .dialogBox .inputTitle i {
		font-style: normal;
		font-size: 20px;
		font-family: 'Arial';
		font-weight: bold;
		color: #444444;
		color: #FF9000;
	}

	.loginmaskBox .dialogBox .privacy {
		margin-top: 53px;
		font-size: 16px;
		font-family: 'Arial';
		font-weight: bold;
		color: #444444;
	}

	.loginmaskBox .dialogBox .privacy .el-checkbox__input {
		width: 30px;
		height: 30px;
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 3px;
	}

	.loginmaskBox /deep/.el-checkbox__inner {
		width: 30px !important;
		height: 30px !important;
		background: #67B7F0;
	}

	.loginmaskBox .el-checkbox__inner {
		display: inline-block;
		position: relative;
		border: none;
		border-radius: 2px;
		box-sizing: border-box;
		width: 14px;
		height: 14px;
		background-color: #FFF;
		z-index: 1;
		transition: border-color .25s cubic-bezier(.71, -.46, .29, 1.46), background-color .25s cubic-bezier(.71, -.46, .29, 1.46);
	}

	.loginmaskBox .el-checkbox__input.is-checked+.el-checkbox__label {
		font-size: 16px;
		font-family: "Arial";
		font-weight: bold;
		color: #444444;
	}

	.loginmaskBox .el-checkbox__label {
		font-size: 16px !important;
		font-family: "Arial";
		font-weight: bold;
		color: #444444;
	}

	.loginmaskBox .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		width: 30px !important;
		height: 30px !important;
		background: #67B7F0;
		border: none;
	}

	.loginmaskBox .el-checkbox__inner::after {
		box-sizing: content-box;
		content: "";
		border: 1px solid #FFF;
		border-left: 0;
		border-top: 0;
		height: 11px;
		left: 10px;
		position: absolute;
		top: 5px;
		transform: rotate(45deg) scaleY(0);
		width: 5px;
		transition: transform .15s ease-in .05s;
		transform-origin: center;
	}

	.loginmaskBox .dialogBox .signBtn {
		margin-top: 28px !important;
	}

	.loginmaskBox .dialogBox .inputbox {
		width: 100%;
		height: 65px;
		background: #FFFFFF;
		border: 1px solid #E5E5E5;
		border-radius: 10px;
		margin-top: 16px;
		padding: 0 18px;
		font-size: 18px;
		font-family: Arial;
		font-weight: 400;
		color: #444444;
		line-height: 45px;
	}

	.loginmaskBox .dialogBox .placInput {
		font-size: 18px;
		font-family: Arial;
		font-weight: 400;
		color: #444444;
		line-height: 45px;
		opacity: 0.5 !important;
	}

	.loginmaskBox .dialogBox .forgotTxt {
		margin-top: 21px;
		width: 100%;
		text-align: right;
		font-size: 16px;
		font-family: "Arial";
		font-weight: bold;
		color: #8E8E8E;
	}

	.loginmaskBox .dialogBox button {
		width: 100%;
		height: 65px;
		background: #67B7F0;
		border-radius: 10px;
		font-size: 20px;
		font-family: "Arial";
		font-weight: bold;
		color: #FFFFFF;
		margin-top: 67px;
	}

	.loginmaskBox .dialogBox .dontTxt {
		width: 100%;
		text-align: center;
		margin-top: 19px;
		font-size: 16px;
		font-family: "Arial";
		font-weight: 400;
		color: #444444;
	}

	.loginmaskBox .el-icon-close:before {
		content: "";
	}

	.loginmaskBox .el-dialog .closeBox {
		width: 100%;
		text-align: center;
	}

	.loginmaskBox .el-dialog .closeBox .maskClose {
		width: 52px;
		height: 95px;
	}

	/* Chrome */
	::-webkit-input-placeholder {
		color: #444444;
		opacity: 0.5;
	}

	/* IE 10+ */
	:-ms-input-placeholder {
		color: #444444;
		opacity: 0.5;
	}

	/* Firefox 4-18 */
	/* Firefox 19+ */
	:-moz-placeholder,
	::-moz-placeholder {
		color: #444444;
		opacity: 0.5;
	}
</style>
