<template>
	<div class="footer flex align-center justify-center">
		<div class="footerContent flex flex-column align-center justify-between">
			<div class="topBox flex align-start justify-between">
				<div class="leftBox flex flex-column align-start">
					<p class="title">Shop</p>
					<div class="topBox_bot flex flex-column align-start">
						<p v-for="(item,index) in shopList" class="cursor" @click="locationHref(item,index)"><a href="javascript:void(0);" class="headera flex justify-center align-center">{{item.txt}}</a></p> 
					</div>
				</div>
				<div class="leftBox flex flex-column align-start">
					<p class="title">Help</p>
					<div class="topBox_bot flex flex-column align-start">
						<p v-for="(item,index) in helpList" class="cursor" @click="pageTohelp(item)" v-if="index<10"><a href="javascript:void(0);" class="headera flex justify-center align-center">{{item.txt}}</a></p>
					</div>
				</div>
				<div class="rightBox flex flex-column align-start">
					<p class="title">GIVE US SUGGESTIONS</p>
					<div class="box flex flex-column align-center">
						<p class="boxTitle">YOUR SUGGESTION WILL MASK US DO BETTER</p>
						<textarea v-model="message" placeholder="Please enter your comments" placeholder-class="placTextarea"></textarea>
						<button class="cursor" @click="messageSave" style="border-radius: 65px;">SUBMIT</button>
					</div>
				</div>
			</div>
			<p class="botBox">COPYRIGHT©BEEFRESH 2021-2022 IT IS AGAINST THE LAW TO SELL OR SUPPLY ALCOHOL TO,OR TO OBTAIN ALCOHOL ON BEHALF OF, A PERSON UNDER THE AGE OF 18 YEARS</p>
		</div>
	</div>
</template>

<script>
	// 这里是引入 element-ui 的组件在页面js 中调用
	import {
		Notification,
		MessageBox,
		Message
	} from 'element-ui'
	import store from '@/store'
	export default {
		data() {
			return {
				shopList:[
					{
						txt:'Specials',
						href: '/listBuy',
						name:'listBuy'
					},
					{
						txt:'Pre-sale',
						href: '/listPre',
						name:"listPre"
					},
					{
						txt:'Seafood',	//好东西的优化
						href: '/list?id=86',
						name:"list",
						id:86,
					},
					// {
					// 	txt:'Promotions',	//促销活动
					// 	href: '#'
					// },
					{
						txt:'Meat & Poultry',
						href: '/list?id=85',
						name:"list",
						id:85,
					},
					{
						txt:'Fruit & Vegetables',
						href: '/list?id=132',
						name:"list",
						id:132,
					},
					{
						txt:'Frozen Food',
						href: '/list?id=102',
						name:"list",
						id:102,
					},
					{
						txt:'Cooked Food',
						href: '/list?id=424',
						name:"list",
						id:424,
					},
					{
						txt:'Diet Meals',
						href: '/list?id=351',
						name:"list",
						id:351,
					},
					{
						txt:'Grain & Oil & Condiment',
						href: '/list?id=218',
						name:"list",
						id:218,
					}
				],
				helpList:[
					{
						txt:'Refund & Return Policy',
						href: '/page?id=3',
						name:"page",
						id:3,
					},
					// {
					// 	txt:'Terms and Conditions',
					// 	href: '/page?id=4'
					// },
					{
						txt:'Privacy Policy',
						href: '/page?id=2',
						name:"page",
						id:2,
					}
				],
				classificationList:[],
				message:''
			};
		},
		async mounted() {
			let headerCategory = await this.$api.getFirstCategory();
			
			headerCategory.data.forEach(res=>{
				this.classificationList.push({
					txt: res.name_en,
					href: '/list?id='+res.id
				});
			});
		},
		methods: {
			locationHref(item,index){
				// if(index==0 || index==1){
				// 	this.$router.push({
				// 		path:item.href
				// 	})
				// }else{
					this.$router.push({
						name: item.name,
						params: {
							one: item.txt,
							id: item.id,
						}
					})
				// }
			},
			pageTohelp(item){
				this.$router.push({
					path:item.href
				})
			},
			messageSave(){
				if(this.message.length<1){
					return this.$message('Please enter your comments');
				}
				
				this.$api.message(this.message).then(res=>{
					this.$message({
						message:res.msg,
						type:'success'
					});
					this.message = '';
				});
			}
		},
	};
</script>
<style>
	
</style>

	