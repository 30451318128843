import axios from "axios";
import store from '@/store';
import qs from 'qs';
import getSign from "@/utils";
import { Notification, MessageBox, Message } from 'element-ui'

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 600000
})

service.interceptors.request.use(config => {
    // 是否需要设置 token
	config.data.token = store.state.userToken;
	config.data.lang = 'en-US';
    if(config.method === 'post') {
        let data = qs.parse(config.data)
        //计算签名
        config.data = qs.stringify(getSign({
            timestamp:Date.parse(new Date()) / 1000,
            platform:'pc',
            ...data
        }))

    } else if(config.method === 'get') {

        config.params = getSign({
            timestamp:Date.parse(new Date()) / 1000,
            platform:'pc',
            ...config.params
        })
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 0;
        // 获取错误信息
        const msg = res.data.msg
        if (code === 419 || code===401) {
            MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
				console.log(store.state.layer,'aaaa');
                store.dispatch('LogOut').then(() => {
					console.log(store.state.layer,'aaaa');
					//store.commit('setlayer','login');
					location.href = '/';
					// location.reload();
                }).catch((e)=>{
					alert(e)
				})
            })
        } else if (code !== 0) {
            Notification.error({
                title: msg
            })
            return Promise.reject('error')
        } else {
            return res.data
        }
    },
    error => {
        console.log('err' + error)
        Message({
            message: error,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
