import request from "./request";
import { Loading } from 'element-ui';
/**
 * 统一api管理
 * @param data
 * @returns {AxiosPromise}
 */

//获取系统参数   （自提地址） 
export function system(data) {
	return request({
		url: "v1.index/system",
		method: "post",
		data: data
	});
}

export function message(message){
	return post('index/message', {message});
}

// 公共接口 上传图像
export function upload(data) {
	return request({
		url: "images/upload",
		method: "post",
		data: data
	});
}
//首页推荐数据
export function IndexGroupbuy(){
	return post('groupbuy/index', {});
}
//列表
export function getGroupbuyList(pageNum = 1, pageSize = 12){
	return post('groupbuy/groupbuyList', {pageNum, pageSize});
}

/**
 * 购物车相关
 */
//立即购买
export function buyCart (id, spec_id, num){
	return post('goods/buycart', {id, spec_id, num});
}

export function addcart(id, num, spec_id, type=1){
	return post('goods/addcart', {id,num,spec_id,type});
}
//清空购物车
export function clearCart(){
	return post('goods/clearcart', {});
}
//统计购物车内数量
export function tolCart(){
	return post('goods/cartnum', {});
}
//获取购物车数据
export function getCartList() {
	return post('goods/cartList', {});
}
export function getCartBag () {
	return post('goods/getCartBag', {});
}
export function addshopcart(id){
	return post('goods/addshopcart', {id});
}
export function subPushCart (id){
	return post('goods/editcart', {act:'sub',id});
}
export function addPushCart (id) {
	return post('goods/editcart', {act:'add',id});
}
export function delCart(id) {
	return post('goods/delcart', {id});
}
export function getCartIds (ids){
	return post('goods/cartList', {ids:ids});	//购物车数据
}
export function cartCreateOrder (ids, addr_id,couponList,total, desc, delivery_date,order_id,time_id,mode,contact,is_replace=false, couponCode, is_delivery_address = 0, site_id = 0) {
	return post('order/created', {ids, addr_id,couponList,total, desc, delivery_date,order_id,time_id,mode, contact, is_replace, couponCode, is_delivery_address, site_id});	//创建订单
}

//优惠券相关调用
export const getCouponList = (goods_id = 0, pageNum = 1, pageSize = 10) => post('coupon/couponList', {goods_id, pageNum, pageSize});
export const receiveCoupon = (coupon_id) => post('coupon/receiveCoupon', {coupon_id});//领取优惠券
export const myCoupon = (status, pageNum = 1, pageSize = 10) => post('coupon/myCouponList', {status, pageNum, pageSize});	//我的优惠券
export const handselCoupon = (id, username) => post('coupon/handselCoupon', { id, username});	//赠送优惠券
//下单时的优惠券
export const orderCoupon = (goods_ids,addr_id)=> post('goods/coupon', { goods_ids,addr_id});


//获取我的订单
export const getOrderInfo = (ordersn) => post('order/orderInfo', {ordersn});	//地址信息
export const orderList = (type, pageNum, pageSize) => post('order/orderList',{type, pageNum, pageSize});
export const getOrderDetail = (ordersn) => post('order/detail', {ordersn});
export const getUnionOrder = (union_id) => post('order/union_order',{union_id});

//获取订单信息
export const repayOrder = (ordersn, paytype, remark = '') =>post('order/orderPcPay',{ ordersn, paytype, remark});
//再次支付
export const  repayCreate = (id,num,addr_name,addr_mobile,address,user_note,paytype,url)=>post('goods/repayment',{id,num,addr_name,addr_mobile,address,user_note,paytype,url})

//发起取消订单（已付款退款）
export const requestCancel = (ordersn, type = 0) => post('order/cancelOrder',{ordersn, type});
//未支付订单取消
export const cancelOrder = (ordersn) => post('order/cancelOrder', { ordersn, type:2});

/**
 * 获取产品数据相关
 */
export function getFirstCategory(child = 1){
	return post('index/categoryone', { child });
}

export function categoryDetail(id){
	return post('index/categoryDetail', { id });
}

export function getCategoryList(params = {}){
	return post('index/categoryGoods', params);
}

//获取商品详情
export function getGoodsDetail(id, type = 1){
	return post('goods/goodsDetail', {id, type});
}

//获取标签数据
export const getFlagGoods = (flag, pageNum, pageSize) => post('goods/flag', {flag, pageNum, pageSize});

//预售商品详情
export function preGoodsDetail(id){
	return post('goods/preGoods', {id});
}
//预售订单下单
export const preCreatedOrder = (id, number,sku_id,addr_id,couponList,total, desc) => post('order/preCreatedOrder', {id,number,sku_id, addr_id,couponList,total, desc})

/**
 * 用户登录相关
 * @param {Object} account
 * @param {Object} password
 * @param {Object} code
 * @param {Object} type 
 */
export function userLogin(account, password, code = '', type = 'password'){
	return post('login/index', {account, password, code, type});
}

export const userForget = (mobile, code, newPassword) => post('login/forget', {mobile, code, newPassword});
//实名认证
export const userAuth = (info) => post('user/adentityAuth', info);
//用户注册
export const userReg = (params) => post('login/reg', params)
export const logout = () => post('user/logout');

//用户二维码
export const userQrcode = (domain) => post('login/qrcode', {domain});

//获取用户默认收货地址
export const getUserAddressDefault = () => post('address/defaultAddr', {});
//获取区域
//添加编辑收货地址
export const editAddress = (consignee, mobile, front_value, address, gender, email, addr_id)=>post('address/editAddress',{consignee, mobile, front_value, address, gender, email, addr_id})
//收货地址列表
export const addressList = ()=>post('address/addressList',{})
//删除收货地址
export const addressDel = (addr_id)=>post('address/addressDel',{addr_id})
//设置默认地址
export const setDefault = (id)=>post('address/setDefault',{id})
//获取区域列表
export const getRegion = () => post('address/getRegionLabel')
//获取运费信息
export const getShippingFee = (ids, skuId = 0, num = 0, isPre = 0) => post('address/shippingFee', {ids, skuId, num, isPre})

//广告数据
export const getAdv = (id) => post('index/getAdv', {id});

//通知 & 文章相关
export const getNotice = () => post('index/articleList', {cid: 1});
export const getArticleView = (id = 0) => post('index/articleDetail', {id});	//获取最新一条公告
export const getArticleList = ( pageNum, pageSize, cid = 1) => post('index/articleList', { pageNum, pageSize,cid});
export const getArticlePage = (id) => post('index/articlePage', {id});

//发送验证码
export const sendCode = (mobile,type)=>post('login/sendCode',{mobile,type})

export const recordEmail = (email) => post('index/recordEmail', {email});

export function post(uri, data = {}, params = {isLoadingHide:false}) {
	console.log('post.params', uri, data);
	if(!params.isLoadingHide){
		//let load = Loading.service({ fullscreen: true });
	}
	let res = request({
		url: "v1."+uri,
		method: "post",
		data: data
	});
	if(!params.isLoadingHide){
		//load.close();
	}
	return res;
} 
