import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({  //注册sotre
    state: {
        userToken:'',
        userInfo:{},
		isLoginShow:0,	//1登录，2注册，3忘记密码
    },
    mutations: {
		setLoginShow(state, payload){
			state.isLoginShow = payload;
		},
        setUserToken(state, payload){
            state.userToken = payload
        },
        setUserInfo(state, payload){
            state.userInfo = payload
        },
		clearUserinfo(state, payload){
			state.userToken = '';
			state.userInfo = {};
		}
    },
    actions: {
        // 退出系统
        LogOut({ commit }) {
            return new Promise((resolve) => {
                commit("setUserToken","")
                commit("setUserInfo",{})
				resolve()
            })
        },
    },
    getters: {},
    modules: {},
    plugins:[
        createPersistedState({
            storage:window.sessionStorage
        })
    ],
});
