<template>
  <div id="app" class="column">
    <!-- 公共头部部 -->
    <Header/>
    <router-view :key="$route.fullPath" />
    <!-- 公共底部 -->
	<Advertise/>
    <Footer/>
  </div>
</template>

<script>

import  Header from '@/components/header.vue';
import  Footer from '@/components/footer.vue';
import  Advertise from '@/components/advertise.vue';

export default {
  name: 'App',
  components:{
    Header,
    Footer,
	Advertise
  },
  data(){
    return {
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>

<style scoped >
  /* 这个样式你到时候可以删了 */
 /* .column{
    height: 100vh;
    padding: 0 8vw;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  } */
</style>
