//取值
export const get = (key, sync = false) => {
	return localStorage.getItem(key)
}

//赋值
export const set = (key, value, sync = false) => {
	return localStorage.setItem(key, value)
}

//移除
export const del = (key, sync = false) => {
	return localStorage.removeItem(key)
}

//清空
export const clear = (sync = false) => {
	return localStorage.clear()
}


