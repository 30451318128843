//正式机地址
export const basePicUrl = 'https://images.b-one.plus/';
export const baseUrl	= 'https://b-one.plus/api.php/';

//国内测试环境
// export const basePicUrl = 'https://b-one.dujiajuhui.com/';
// export const baseUrl = 'https://b-one.dujiajuhui.com/api.php/';


//本地测试环境
// export const basePicUrl = 'http://b-one.zczc.net/';
// export const baseUrl	= 'http://b-one.zczc.net/api.php/';
